// import * as React from "react"
// import { graphql, Link } from "gatsby"

// import Layout from "../components/layout"
// import Seo from "../components/seo"

// const NotFoundPage = ({ data, location }) => {
//   const siteTitle = data.site.siteMetadata.title

//   return (
//     <Layout location={location} title={siteTitle}>
//       <Seo title="404: Not Found" />
//       <h1>404: Not Found</h1>
//       <p>ページが見つかりませんでした。</p>
//       <p>
//         <Link to="/">⇒ホームへ戻る</Link>
//       </p>
//     </Layout>
//   )
// }

// export default NotFoundPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `

import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TagsList from "../components/organisms/tags-list"
import { Box, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const sideBarContents = [
    <TagsList />,
  ]


  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
      isTwoColumn={false}
      bgColor="white"
    >
      <Seo title="指定されたページが見つかりません" />
      <Heading as="h1" textAlign="center" my="0.8em">
        <Box fontSize="2rem" color="black">
          404：指定されたページが見つかりません
        </Box>
      </Heading>
      {/* 画像 */}

      <Box position="relative">
        <Box opacity="1">
          <StaticImage
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            src="../images/houses-light-cut.png"
            alt="イメージ画像"
          />
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/myblog/" } }
      sort: { fields: frontmatter___created, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          title
          modified_date(formatString: "YYYY/M/DD")
          created(formatString: "YYYY/M/DD")
          tags
          category
          description
          thumbnail_image {
            childImageSharp {
              gatsbyImageData(width: 300, layout: CONSTRAINED)
            }
          }
        }
        parent {
          ... on File {
            sourceInstanceName
          }
        }
      }
    }
  }
`
